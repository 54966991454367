<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button
          label="اضافه جديد"
          icon="pi pi-plus"
          class="p-ml-2 p-button-success"
          v-if="$checkRoles('academiesAdd')"
          @click="$router.push('academies/add')"
        />
        <Button
          label="حذف"
          icon="pi pi-trash"
          @click="deleteAll"
          v-if="$checkRoles('academiesDelete')"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        />
      </template>

      <template #right>
        <!-- <MultiSelect
          :value="selectedLeagueList"
          :options="leagueList"
          optionLabel="name"
          optionValue="id"
          @input="onToggleLeagueList"
          placeholder="المسابقات"
          style="width: 20em"
          class="p-ml-2"
        /> -->

        <Button
          icon="pi pi-cog"
          v-tooltip="'التحكم في الاكاديميه'"
          @click="openC"
          class="p-button-basic p-ml-2"
        />
        <Dialog :visible.sync="display">
          <template #header>
            <h3>التحكم</h3>
          </template>
          <div>
            <div class="mb-3">
              <b-form-group label="المحدد" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  v-model="obj.selected"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  name="radio-inline"
                ></b-form-radio-group>
              </b-form-group>
            </div>
            <div class="mb-3">
              <label>
                الاضافه
              </label>
              <InputSwitch v-model="obj.lockAdd" />
            </div>
            <div class="mb-3">
              <label>
                التعديل
              </label>
              <InputSwitch v-model="obj.lockUpdate" />
            </div>
            <div class="mb-3">
              <label>
                الحذف
              </label>
              <InputSwitch v-model="obj.lockDelete" />
            </div>
            <!-- <div class="mb-3">
              <b-form-group
                label="المحدد"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  v-model="obj.selected"
                  :options="options2"
                  :aria-describedby="ariaDescribedby"
                  name="radio-inline"
                ></b-form-radio-group>
              </b-form-group>
            </div>
            -->
          </div>
          <template #footer>
            <Button
              label="تنفيذ"
              @click="updateAll"
              icon="pi pi-check"
              autofocus
            />

            <Button
              label="الغاء"
              @click="display = false"
              icon="pi pi-times"
              class="p-button-text"
            />
          </template>
        </Dialog>
        <Button
          icon="pi pi-download"
          class="p-button-help"
          v-tooltip="'تحميل'"
          @click="exportCSV($event)"
        />
        <!-- <Button icon="pi pi-search" class="p-ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers"
      :rows="100"
      dataKey="id"
      :rowHover="true"
      :filters="filters"
      :selection.sync="selectedItems"
      :loading="loading"
      :expandedRows.sync="expandedRows"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه الأكاديميات

          <div style="text-align:left">
            <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="اختيار الاعمده"
              style="width: 20em"
            />
          </div>
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <Column selectionMode="multiple" field="id" headerStyle="width: 3em" />
      <Column
        field="image"
        header="الشعار"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <img :src="$baseUploadURL + slotProps.data.image" class="dt-image" />
        </template>
      </Column>
      <Column
        v-for="(col, index) of selectedColumns"
        :key="col.field + '_' + index"
        :field="col.field"
        :header="col.header"
        :sortable="true"
      >
        <template #filter>
          <input
            class="form-control"
            :id="col.field"
            v-model="filters[col.field]"
          />
        </template>
      </Column>

      <Column field="id" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <Button
            type="button"
            @click="print_page(slotProps.data.id)"
            class="p-button-help p-ml-2  p-button-rounded"
            icon="pi pi-download"
            v-tooltip="'طباعه بيانات الاكاديميه'"
          ></Button>

          <Button
            type="button"
            @click="$router.push('academies/league/' + slotProps.data.id)"
            icon="pi pi-cog"
            v-if="$checkRoles('leagueYears')"
            class="p-button-info p-ml-2  p-button-rounded"
            v-tooltip="'السنوات المشترك بها'"
          ></Button>

          <Button
            type="button"
            @click="$router.push('academies/edit/' + slotProps.data.id)"
            icon="pi pi-pencil"
            v-if="$checkRoles('academiesEdit')"
            class="p-button-success p-ml-2  p-button-rounded"
            v-tooltip="'تعديل'"
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash"
            v-if="$checkRoles('academiesDelete')"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning  p-button-rounded"
            v-tooltip="'حذف'"
          ></Button>
        </template>
      </Column>
      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable :value="[slotProps.data]" class="p-datatable-customers">
            <Column
              field="id"
              header="الكل"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <InputSwitch
                  :value="
                    slotProps.data.lockAdd &&
                      slotProps.data.lockUpdate &&
                      slotProps.data.lockDelete
                  "
                  @change="updateStatus(slotProps.data, 'all')"
                />
              </template>
            </Column>

            <Column
              field="id"
              header="الاضافه"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <InputSwitch
                  v-model="slotProps.data.lockAdd"
                  @change="updateStatus(slotProps.data, 'add')"
                />
              </template>
            </Column>
            <Column
              field="id"
              header="التعديل"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <InputSwitch
                  v-model="slotProps.data.lockUpdate"
                  @change="updateStatus(slotProps.data, 'update')"
                />
              </template>
            </Column>
            <Column
              field="id"
              header="الحذف"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <InputSwitch
                  v-model="slotProps.data.lockDelete"
                  @change="updateStatus(slotProps.data, 'delete')"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      options: [
        {
          text: 'الكل',
          value: 'all',
        },
        {
          text: 'المحدد',
          value: 'selcted',
        },
      ],
      obj: {
        selected: 'all',
        lockAdd: false,
        lockUpdate: false,
        lockDelete: false,
      },

      filters: {},
      display: false,
      expandedRows: [],
      selectedItems: [],
      selectedColumns: [],

      columns: [
        {
          field: 'userName',
          header: 'الاسم',
        },
        {
          field: 'email',
          header: 'البريد الألكتروني',
        },
        {
          field: 'phone',
          header: 'الهاتف',
        },
      ],
      loading: true,
      leagueList: [],
    };
  },
  methods: {
    openC() {
      this.obj.selected = 'all';
      this.obj.lockAdd =
        this.list.find((el) => el.lockAdd == false) == undefined;
      this.obj.lockUpdate =
        this.list.find((el) => el.lockUpdate == false) == undefined;
      this.obj.lockDelete =
        this.list.find((el) => el.lockDelete == false) == undefined;
      this.display = true;
    },
    updateAll() {
      const xbody = {
        lockAdd: this.obj.lockAdd,
        lockUpdate: this.obj.lockUpdate,
        lockDelete: this.obj.lockDelete,
      };
      if (this.obj.selected == 'all') {
        this.$http.post(`academies/updateCanMany`, xbody).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.getData();
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else if (this.obj.selected == 'selcted') {
        if (this.selectedItems.length > 0) {
          this.$http
            .post(`academies/updateCanManyList`, {
              xbody,
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.$toast.add({
                  severity: 'success',
                  summary: 'تم بنجاح',
                  detail: 'تم التعديل بنجاح',
                  life: 3000,
                });
                this.getData();
              },
              (err) => {
                this.loading = false;
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: 'ليس هناك عناصر محدده',
            life: 3000,
          });
        }
      }
      this.display = false;
    },
    getData() {
      this.$http.get(`academies`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
          this.$http.get(`league`).then((response) => {
            this.leagueList = response.data;
          });
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    print_page(id) {
      this.$http.get(`academies/print/${id}`).then(
        (res) => {
          window.open(this.$baseZipURL + res.data, '_blank');
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    updateStatus(item, type) {
      let obj = {
        lockAdd: !item.lockAdd,
        lockUpdate: !item.lockUpdate,
        lockDelete: !item.lockDelete,
      };
      if (type == 'add') {
        obj = {
          lockAdd: item.lockAdd,
        };
      }
      if (type == 'update') {
        obj = {
          lockUpdate: item.lockUpdate,
        };
      }
      if (type == 'delete') {
        obj = {
          lockDelete: item.lockDelete,
        };
      }
      this.$http.post(`academies/updateCan/${item.id}`, obj).then(
        () => {
          this.$toast.add({
            severity: 'success',
            summary: 'تم بنجاح',
            detail: 'تم التعديل بنجاح',
            life: 3000,
          });
          this.getData();
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http
            .post(`academies/deleteList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                for (const item of this.selectedItems) {
                  this.list.splice(
                    this.list.findIndex((e) => e.id == item.id),
                    1,
                  );
                }
                // this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`academies/${id}`).then(
            () => {
              // this.getData();
              this.list.splice(
                this.list.findIndex((e) => e.id == id),
                1,
              );
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
  },
  created() {
    this.getData();
    this.selectedColumns = this.columns;
  },
};
</script>

<style>
.col-form-label {
  text-align: start;
}
</style>
